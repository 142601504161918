<template>
	<div class="print-wrap-detail" v-loading="loadType" element-loading-text="数据加载中...">
		<div class="page-main">
			<div class="print-main">
				<p class="print-main-title">
					<!-- 标题 -->
					XX省体育科学技术研究所检验报告单
					<el-button class="brotherBtns" @click="print" v-if="btnShow">
						<i class="iconfont icon-dayin-icon" style="color:#1BBC9B"></i>
						<span>打印</span>
					</el-button>
				</p>

				<el-form ref="form" :model="form" label-width="60px" label-position="left">
					<div class="div-flex">
						<el-form-item label="姓 名">: {{ form.username }}</el-form-item>
						<el-form-item label="病人类型">: {{ form.person_type }}</el-form-item>
						<el-form-item label="床  号">: </el-form-item>
						<el-form-item label="标  本  号">: {{ form.specimen_no }}</el-form-item>
					</div>
					<div class="div-flex">
						<el-form-item label="性 别">: {{ form.sex }}</el-form-item>
						<el-form-item label="住 院 号">: </el-form-item>
						<el-form-item label="费 别">: </el-form-item>
						<el-form-item label="标本类型">: </el-form-item>
					</div>
					<div class="div-flex">
						<el-form-item label="年 龄">: {{ form.age }} 岁</el-form-item>
						<el-form-item label="科 室">: </el-form-item>
						<el-form-item label="诊 断">: </el-form-item>
						<el-form-item label="备 注">: </el-form-item>
					</div>
				</el-form>

				<div class="print-main-content">
					<el-table :data="tableDataLeft" border style="width: 50%;float: left;">
						<el-table-column prop="parameter" label="代号" align="left">
							<template slot-scope="scope"> {{ scope.row.test_item }} </template>
						</el-table-column>
						<el-table-column prop="result" label="项目" align="left">
							<template slot-scope="scope"> {{ scope.row.test_xmmc }}</template>
						</el-table-column>
						<el-table-column prop="result" label="结果" align="left">
							<template slot-scope="scope">
								{{ scope.row.test_value }} * {{ scope.row.test_dw }} <i v-if="scope.row.test_result == 1" class="el-icon-bottom" style="color: #FF8D03;font-size: 16px;"></i
								><i v-else-if="scope.row.test_result == 3" class="el-icon-top" style="color: #FF0000;font-size: 16px;"></i
							></template>
						</el-table-column>
						<el-table-column prop="test_dyckz" label="参考值" align="left"> </el-table-column>
					</el-table>
					<el-table :data="tableDataRight" border style="width: 50%;float: right;">
						<el-table-column prop="parameter" label="代号" align="left">
							<template slot-scope="scope"> {{ scope.row.test_item }} </template>
						</el-table-column>
						<el-table-column prop="result" label="项目" align="left">
							<template slot-scope="scope"> {{ scope.row.test_xmmc }}</template>
						</el-table-column>
						<el-table-column prop="result" label="结果" align="left">
							<template slot-scope="scope">
								{{ scope.row.test_value }} * {{ scope.row.test_dw }} <i v-if="scope.row.test_result == 1" class="el-icon-bottom" style="color: #FF8D03;font-size: 16px;"></i
								><i v-else-if="scope.row.test_result == 3" class="el-icon-top" style="color: #FF0000;font-size: 16px;"></i
							></template>
						</el-table-column>
						<el-table-column prop="test_dyckz" label="参考值" align="left"> </el-table-column>
					</el-table>
					<div class="print-main-content-bot">
						<div v-for="(itm, idx) in form.img">
							<p>
								{{ idx }}
							</p>
							<img :src="'http://' + itm" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'print',
	data() {
		return {
			btnShow: true,

			coach_name: '',

			weekData: {},
			newWeekData: [],
			departmentName: '',

			tableList: [],

			loadType: true,
			form: {},

			tableDataLeft: [],
			tableDataRight: [],
		}
	},
	created() {},
	mounted() {
		this.$axios
			.post(
				'/p/equipment_record/report',
				this.$qs({
					specimen_no: this.$route.query.specimen_no,
					test_date: this.$route.query.date,
				})
			)
			.then(res => {
				this.loadType = false
				this.form = res.data.data
				res.data.data.infos.forEach((item, index) => {
					if (index % 2 == 0) {
						this.tableDataLeft.push(item)
					} else {
						this.tableDataRight.push(item)
					}
				})
			})
	},
	methods: {
		print() {
			this.btnShow = false
			setTimeout(() => {
				window.print()
			}, 0)
			setTimeout(() => {
				this.btnShow = true
			}, 2000)
		},
	},
}
</script>
<style lang="scss">
html,
body {
	width: 100%;
	height: auto !important;
	overflow: auto !important;
}
#app {
	height: auto !important;
}
.print-wrap-detail {
	width: 100%;
	height: 100%;
	overflow: auto;
	// background: #eff1f5;
	page-break-inside: avoid;
	.page-main {
		width: 1250px;
		// border: 1px solid #000;
		margin: 0 auto;
		// background: #999	;
		// min-height: 100%;
		// border-left: 1px solid #ccc;
		// border-right: 1px solid #ccc;
		// background: #daeeff;
		padding-bottom: 20px;

		.print-main {
			.el-form {
				margin-top: 10px;
				.div-flex {
					display: flex;
					.el-form-item {
						flex: 1;
						margin-bottom: 0;
					}
				}
			}

			.print-main-title {
				width: 100%;
				text-align: center;
				padding-top: 30px;
				font-size: 35px;
				font-weight: 500;
				position: relative;
				color: #000;
				// background: linear-gradient(180deg, #35a2dc 0%, #1869b6 100%);
				.brotherBtns {
					position: absolute;
					top: 20px;
					right: 20px;
				}
			}
			.print-main-date {
				display: flex;
				span {
					font-size: 18px;
					display: inline-block;
					width: 50%;
					text-align: center;
				}
			}
			.print-main-content {
				// margin: 0 20px;
				padding-top: 20px;

				.print-main-content-title {
					display: flex;
					justify-content: space-between;
				}
				> h2 {
					width: 150px;
					line-height: 40px;
					font-size: 18px;
					font-weight: 400;
					// background: #1a87dd;
					color: #fff;
					text-align: center;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
				> ul {
					display: flex;
					flex-wrap: wrap;
					background: #fff;
					justify-content: space-between;
					padding: 20px 15px 0px;
					li {
						width: 48%;
						// min-height: 40px;
						border-radius: 10px;
						// border: 1px solid #1a87dd;
						margin-bottom: 20px;
						display: flex;
						align-items: center;
						strong {
							color: #dd2d1a;
							margin-right: 5px;
						}
						span {
							width: 120px;
							color: #000;
							// border-right: 1px solid #fff;
							display: flex;
							height: 100%;
							align-items: center;
							justify-content: center;
							padding: 10px 0;
							box-sizing: border-box;
							font-weight: 500;
						}
						p {
							flex: 1;
							padding: 10px 10px 10px 20px;
							display: flex;
							align-items: center;
							box-sizing: border-box;
						}
					}
				}

				.el-table {
					margin-top: 20px;
					/deep/ .el-table__header thead tr {
						background: #cfc;
					}
				}
			}

			.print-main-content-bot {
				background: #fff;
				// padding-bottom: 30px;
				display: flex;
				width: 100%;
				margin-top: 30px;
				justify-content: space-between;
				> div {
					width: 31%;
					height: auto;
					// border: 1px solid #ccc;
					margin-top: 20px;
					// box-shadow: 2px 2px 0px 0px #ccc;
					p {
						text-align: center;
						line-height: 40px;
						font-size: 20px;
					}
					img {
						width: 100%;
						height: auto;
					}
				}

				.print-bot-top-content {
					width: 80%;
					margin-left: 10%;
					display: flex;
					flex-wrap: wrap;
				}

				.print-bot-top-last {
					// margin-bottom: 20px;
				}

				.print-bot-top {
					// border-radius: 10px;
					// border: 1px solid #1a87dd;
					width: 33.3%;
					// margin-bottom: 20px;
					display: flex;
					// line-height: 18px;
					align-items: center;

					&:first-child {
						p {
							text-decoration: none;
							b {
								text-decoration: underline;
								font-weight: 500;
							}
						}
					}
					strong {
						color: #dd2d1a;
						margin-right: 5px;
					}
					span {
						// width: 80px;
						// background: rgba(26, 135, 221, 0.09);
						color: #000;
						// border-right: 1px solid #1a87dd;
						display: flex;
						height: 100%;
						// align-items: center;
						// justify-content: center;
						padding: 5px 0;
						box-sizing: border-box;
						font-weight: 500;
						justify-content: start;
						font-size: 18px;
					}
					p {
						flex: 1;
						font-weight: 500;
						font-size: 17px;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						word-break: break-all;
						word-wrap: break-word;
						overflow: hidden;
						// line-height: 17px;
						text-decoration: underline;
					}
				}

				.print-bot-input {
					display: flex;
					min-height: 50px;
					border: 1px solid #000;
				}

				.print-bot-input-zy {
					display: flex;
					min-height: 50px;
					width: 100%;
					.left_title {
						background: #fff;
						border: none;
					}
				}

				.table_title {
					display: flex;
					// line-height: 50px;
					// padding: 20px 0;
					border-left: 1px solid #000;
					border-top: 1px solid #000;
				}

				.left_title {
					// display: block;
					width: 70px;
					background: #fff;
					text-align: center;
					border-right: 1px solid #000;
					// font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 400;
					// color: #333333;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 10px 15px;
					box-sizing: border-box;
					font-size: 16px;

					span {
						font-size: 14px;
					}
				}

				.right_title {
					flex: 1;
					background: #fff;
					text-align: center;
					border-right: 1px solid #000;
					// font-family: PingFangSC-Medium, PingFang SC;
					// font-weight: 300;
					// color: #333333;
					padding: 10px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: center;
					// white-space: pre-wrap;
					word-break: break-all;
					word-wrap: break-word;
					overflow: hidden;
					text-align: justify;
					font-size: 16px;
					line-height: 23px;
				}

				.right_title_three {
					width: 90px;
					background: #fff;
					text-align: center;
					border-right: 1px solid #000;
					// font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 400;
					// color: #333333;
					padding: 10px 5px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: center;
					// white-space: pre-wrap;
					word-break: break-all;
					word-wrap: break-word;
					overflow: hidden;
					text-align: justify;
				}

				.right_title_demo {
					width: 60px;
				}

				.table_content {
					border-top: 1px solid #000;
					.right_title {
						min-height: 18px;
						background: #fff !important;
					}
					.right_title_three {
						background: #fff !important;
					}
				}

				.right_content {
					flex: 1;
					display: flex;
					align-items: center;
					// justify-content: center;
					// font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 400;
					// color: #333333;
					padding: 20px 10px;
					// white-space: pre-wrap;
					word-break: break-all;
					word-wrap: break-word;
					font-size: 16px;
					line-height: 23px;
					text-indent: 2em;
				}

				.right_content_two {
					padding: 20px 10px;
				}
			}
		}
	}
}

.doping-top {
	position: relative;
	width: 100%;
	height: 62px;
	background: rgba(244, 246, 249, 1);
	border: 1px solid rgba(227, 227, 227, 1);
	line-height: 62px;
	box-sizing: border-box;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
}
.gzqTabStyle {
	margin-top: 30px;
	padding: 0 20px 20px;
}
.lists {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 0 20px;
	box-sizing: border-box;
	margin-top: 40px;
	li {
		font-family: MicrosoftYaHei;
		width: 33%;
		height: 30px;
		font-size: 16px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		span {
			color: #626262;
		}
	}
}
.trainContent {
	width: 100%;
	font-size: 14px;
}

.content_tips {
	padding-left: 100px;
	font-size: 17px;
	color: #000;
	padding-top: 2px;
}
</style>
<style>
/* .contents .el-textarea__inner {
	padding-left: 100px;
	padding-top: 10px;
	line-height: 1;
} */
</style>
